<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-29 17:25:31
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 18:54:20
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\recruit\Recruit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-main home-main">
      <SiteTopbar/>
      <div class="head-img"></div>
    <div class="container">
    <div class="title-img">
      <img src="../../assets/imgs/TKK/ping.png" width="269px" height="158px">
    </div>

      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>技术类</p>
      </div>
      <div class="contents">
          <p>高级前端：
           </p>
           <div>
             <ul>
               <li>●计算机相关专业，基础知识扎实；</li>
               <li>● 熟悉React 、VUE、WebpackHTML5、CSS3、JavaScript、TypeScript ；</li>
               <li>● 有Three.js、Canvas、WebGL开发过3D图形相关经验者优先；</li>
             </ul>
            </div>
          <p>
            图形开发（CAD）：
          </p>
           <div>
             <ul>
               <li>●计算机科学与技术相关专业；</li>
               <li>● 对计算机图形学和3D几何相关技术有较深认识，代码编写基础扎实；</li>
               <li>● 有Three.js、WebGL、OpenGL、OpenCASCADE、Vulkan使用经验优先；</li>
               <li>● 熟练C++编程、OpenGL、熟悉Shader编码；</li>
             </ul>
            </div>            
      </div>

      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>产品类</p>
          
      </div>
      <div class="contents">
          <p>产品经理：</p>
 <div>
             <ul>
               <li>● 统筹管理整个产品生命周期，市场敏锐度高，行业发展及互联网相关技术认知独到；</li>
               <li>● 墨刀、即时等相关产品软件熟悉，据产品要求给出高质量原型图；</li>
               <li>● 了解用户需求，结合产品和技术动态高效形成产品开发方向，并指定开发路径；</li>
               
             </ul>
            </div>     

          <p>产品运营：</p>

 <div>
             <ul>
               <li>● 熟悉互联网产品运营策略，制定强有效的整体运营方案产统筹执行；</li>
               <li>● 用户增长、用户运营与管理、活动方案与计划制定；</li>
               <li>● 市场营销或经营管理相关专业，有哲学、经济学、人文科学方面知识储备；</li>
               
             </ul>
            </div>   
      </div>

      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>设计类</p>
      </div>
      <div class="contents">
          <p>UI设计师：</p>
   <div>
             <ul>
               <li>● 视觉传达和设计类专业，独特审美，对美术、潮流、文化方面有特别认知；</li>
               <li>● 包括图标、界面、主题、交互、视觉、动效、网页、图形、banner、APP、UE、小程序、H5页面设计；</li>
               <li>● 熟练使用墨刀、即时、Photoshop、Illustrator、DW、C4D、AI等设计软件；</li>
               
             </ul>
                </div>  
      </div>

      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>市场类</p>
      </div>
      <div class="contents">
          <p>客户顾问：</p>
               <div>
             <ul>
               <li>● 向客户推介产品，并有效介绍产品功能、操作、使用培训；</li>
               <li>● 与客户高效高质量的沟通，了解与洞查客户潜在需求并梳理出价值信息；</li>
               <li>● 具备自主开发和通过有效信息识别与开发客户的方法与能力，对互联网产品有较深认识；</li>
               
             </ul>
                </div>  
      </div>
      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>职能类</p>
      </div>
      <div class="contents" >
          <p>HR、人事专员：</p>
 <div>
             <ul>
               <li>● 人力资源或相关专业，有猎头和互联网行业工作背景；</li>
               <li>● 了解人力资源各模块工作，熟练掌握招聘及员工关系两大模块；</li>
               <li>● 具备较丰富的企业人才招募和组织体系的建立与推行工作经验；</li>
               
             </ul>
                </div>  
            
      </div>
    </div>
    <Footer/>
    <Info/>
    <Cebian/>
  </div>
</template>

<script>
import SiteTopbar from '@/components/header/SiteTopbar'
import Footer from '@/components/PageMain/Bottom/Footer'
import Info from '@/components/PageMain/Bottom/Info'
import Cebian from '@/components/Cebian';
export default {
  name: "recruit",
  components: {
  SiteTopbar,
    Footer,
    Info,
    Cebian
  },
     data(){
    return{
      isOverWX: false
    }
  },
  methods: {
   
    brief(){
     
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
    created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')

      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  mounted(){
this.brief()
  }
};
</script>

<style>
.home-main {
  /* padding-top: 4px; */
  /* padding-bottom: 12px; */

}
li{
  list-style-type: none;
}
.page-main {
  /* background: #f5f5f5; */
}
.container {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10vh;
  
}
.head-img{
  width: 100%;
  background-image: url('../../assets/imgs/TKK/zhao.png');
    background-repeat:no-repeat;
  height: 80vh;
  background-size: 100%;
}
.title-img{

  display: flex;
  justify-content: center;
  margin-top: 50px;
}
 .content-title{
   margin-top: 100px;

   height: 50px;
    margin-bottom: 20px;
   background-size: 100%;
   background-position:left;
 }
 .content-title p{
   position: relative;
   top: -26px;
   left: 10px;
   color: rgba(69, 77, 89, 1);
font-size: 32px;
font-weight: 700;
 }
 .contents p{

margin-top: 50px;
margin-left: 10px;
color: #2B5FFF;
font-size: 20px;
/* height: 50px;
line-height: 50px; */
font-weight: 600;
text-align: left;
 }
 .contents div{
   font-size: 18px;
  margin-left: 100px;
  color: rgba(69, 77, 89, 1);
 }
</style>